import React from 'react';
import '../App.css';
import TextBox from './TextBox';
import ImageBox from './ImageBox';
import Lister from './Lister';
import Card from 'react-bootstrap/Card';

/**
 * Basic component used to compose
 * all other components
 * @param {Object} props contains data for instatiating
 * other components
 */
function InfoBox(props) {
    
    //only render sub-components 
    //if they exist
    const cardImage = 
        props.imageLink != null ? 
            <ImageBox 
                src={props.imageLink}
                alt={props.imageAltText}
            /> : null;
    
    const text = 
        props.text != null ?
        <TextBox 
            text = {props.text}
        /> : null;

    const list = 
        props.textList != null ?
        <Lister
            list={props.textList}
        /> : null;

    return (
        <Card 
            className="InfoBox" 
            style={{backgroundColor:"transparent"}}
            border="dark"
        >
            {cardImage}
            <Card.Body>
                <Card.Title>
                    {props.boxTitle}
                </Card.Title>
                {text}
                {list}
            </Card.Body>
        </Card>
    ); 
  }
  
  export default InfoBox;