import React from 'react';
import './App.css';
import headshotImg from './images/profilePhoto.jpg';
import scooterImg from './images/Shutterstock_Scooter.jpg';
import dashboardImg from './images/Shutterstock_Dashboard.jpg';
import phoneAppImg from './images/Shutterstock_PhoneApp.jpg';
import wireframeImg from './images/Shutterstock_Wireframe.jpg';
import InfoBox from './components/InfoBox';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import { CardDeck } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { linkedInUrl, gitHubUrl } from './constants/links';

function App() {

  return (
    <div className="App">
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand>
            Salman Anza
          </Navbar.Brand>
          <Navbar.Text>
            salanza10@gmail.com
          </Navbar.Text>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link href={linkedInUrl} target="_blank">
                LinkedIn
              </Nav.Link>
              <Nav.Link href={gitHubUrl} target="_blank">
                GitHub
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Row>
          <Col>
            <img className="Headshot" src={headshotImg} alt="professional headshot"></img>
          </Col>
          <Col sm={8}>
            <div className="ElevatorPitch">
              <p>
                Hello World!
              </p>
              <p>
                I am a Software Engineer based out of Austin, Texas. I'm currently employed by Texas HHSC as a Programmer IV.
                My passion for computer science comes from building legos as a child. What I enjoyed most from building legos was seeing how the small,
                distinct pieces fit together to form a bigger, cohesive structure. The lego pieces were like the different APIs I now compose to create a new service.
                My future goals include architecting a high-demand software platform and visiting the ancient city of Megiddo!
              </p>
            </div>
          </Col>
        </Row>
        <div className="DividerText">
          Recent Work
        </div>
        <CardDeck>
        <InfoBox
            imageLink = {dashboardImg}
            imageAltText = "person adding sticky notes to a board"
            text = "Currently a proud member of Texas HHSC Salesforce Admin App's team. My main responsibilites include facilitating production deployments, maintaining the agency's Salesforce CI/CD platform (Flosum) and debugging and impelmenting new features for existing applications."
          />
          <InfoBox
            imageLink={wireframeImg}
            imageAltText="Person creating wireframes"
            text = "Strengthened Verizon's 'Network Failure Management' by reducing failures in its ticketing system by an average of 74%. This involved analyzing 1400 failures in production and implementing changes to handle errors which were within the scope of the automation."
          />
          <InfoBox 
            imageLink = {scooterImg}
            imageAltText = "an electric scooter"
            text = "Created and published node-jump, a node modlue that wrap's each of Jump's Open Data endpoints into simple, promisified functions."
          />
          <InfoBox
            imageLink = {phoneAppImg}
            imageAltText = "person touching a smart phone"
            text = "Added Jump and Lime for the boons rate-compare application by integrating their APIs into our node-based microservices which gave boons a new addressable market"
          />
        </CardDeck>
      </Container>
    </div>
  );
}

export default App;