import React from 'react';
import '../App.css';
import ListGroup from 'react-bootstrap/ListGroup';

function Lister(props) {
    
    const listItems = props.list.map((text,index) => 
        <ListGroup.Item key={"item"+index}>
            {text}
        </ListGroup.Item>
    );

    return (
        <ListGroup className = "Lister" variant="flush">
            {listItems}
        </ListGroup>
    );
}

export default Lister;