import React from 'react';
import '../App.css';
import Card from 'react-bootstrap/Card';

/**
 * Component for adding images
 * @param {*} props 
 */
function ImageBox(props) {
    return (
        <Card.Img
            variant="top"
            className="ImageBox"
            src={props.src}
            alt={props.alt}
        />
    );
  }

  export default ImageBox;