import React from 'react';
import Card from 'react-bootstrap/Card';
import '../App.css';

/**
 * Text component
 * @param {*} props 
 */
function TextBox(props) {
    return (
      <Card.Text className="TextBox">
        {props.text}
      </Card.Text>
    );
  }
  
  export default TextBox;